
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import '../styles/index.css';
import '@lionstep/ui/dist/index.css';
import '@lionstep/ui-v2/dist/index.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

function MyApp({ Component, pageProps, err }) {
  return (
    <QueryClientProvider client={queryClient}>
      <Component {...pageProps} err={err} />
    </QueryClientProvider>
  );
}

const __Page_Next_Translate__ = MyApp;


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  